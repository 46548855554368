.progressBarContainer {
  width: 300px;
  height: 12px;
  background-color: #e6e6e68c;
  margin-top: 20px;
  border-radius: 6px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.progressBar {
  height: 6px;
  border-radius: 4px;
  background-color: #009FE3;
}
