.resultsPageContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.resultsPageOverlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.resultsContainer {
  position: relative;
  z-index: 30;
  width: 50%;
  min-height: 50%;
  background-color: white;
  border-radius: 20px;
  padding: 30px;
}

.resultsValueContainer {
  margin: 20px 0;
}

.resultsValueContainer.no-margin-top {
  margin-top: 0;
}

.resultsLabel {
  color: #9D9D9D;
  font-size: 16px;
  font-weight: 300;
}

.resultsValue {
  font-size: 22px;
  font-weight: 400;
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 30px;
  appearance: none;
  border: none;
  background: none;
  margin: 0;
  padding: 5px;
  cursor: pointer;
}

.closeIcon {
  width: 16px;
}

.downloadButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}