.fileInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputBox {
  width: 200px;
  min-height: 225px;
  border-radius: 20px;
  box-shadow: 0px 2px 200px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border: 2px solid #FFFFFF;
}

.inputBox.dragActive {
  border: 2px solid #009FE3;
  background-color: rgba(0, 159, 227, 0.1);
}

.inputBox.inputBoxError {
  border: 2px solid #FC1E1E;
  background-color: #FFFFFF;
}

.inputLabel {
  text-align: center;
  color: #0E518A;
  display: flex;
  height: 30px;
  vertical-align: baseline;
  align-items: center;
}

.upArrow {
  width: 20px;
  height: 20px;
  position: absolute;
}

.inputText {
  font-weight: 100;
  color: #737373;
  text-align: center;
}

.inputContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
  min-height: 100px;
}

.fileName {
  text-align: center;
  margin: 0;
  min-height: 16px;
  color: #737373;
  font-size: 11px;
  word-break: break-all;
}

.inputTextError {
  color: #FC1E1E;
}

.chooseFileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 30px;
  background: #009FE3;
  border-radius: 14px;
  color: #FFF;
  cursor: pointer;
}

.chooseFileButton:hover {
  background-color: #006c9a;
}

.fixFileButton {
  margin-top: 10px;
}
